
import { defineComponent } from 'vue'
import { calculateFinancialSummary } from '@/api/advance.ts';
import dayjs from 'dayjs';
const fristDay = dayjs().startOf('month').format('YYYY-MM-DD')
const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')
export default defineComponent({
    props: {
        date: {
            type: Array,
            default() {
                return [fristDay, lastDay]
            }
        }
    },
    data() {
        return {
            details: {
                all: {
                    name: '当前共发（元）',
                    allMoney: 0,
                    paymentTotalMoney: 0,
                    advanceTotalMoney: 0,
                    className: 'fc-blue'
                },
                alr: {
                    name: '当前共领（元）',
                    alrPrice: 0,
                    paymentAlrMoney: 0,
                    advanceAlrMoney: 0,
                    className: 'fc-success'
                },
                ait: {
                    name: '当前未领（元）',
                    aitPrice: 0,
                    paymentWaitMoney: 0,
                    advanceWaitMoney: 0,
                    className: ''
                },
                stop: {
                    name: '当前共停发（元）',
                    stopPrice: 0,
                    paymentStopMoney: 0,
                    advanceStopMoney: 0,
                    className: 'fc-orange'
                }
            }
        }
    },
    watch: {
        date: {
            handler(newval) {
                if (newval) {
                    this.getData()
                }
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
    },
    methods: {
        getData() {
            calculateFinancialSummary({
                startDate: (this as any).date[0],
                endDate: (this as any).date[1]
            }).then((res: any) => {
                for (const key in this.details) {
                    for (const key1 in (this as any).details[key]) {
                        if(typeof (this as any).details[key][key1] === 'number') {
                            (this as any).details[key][key1] = res[key][key1]
                        } 
                    }
                }
                console.log(1111, this.details);
            })
        }
    }
})
