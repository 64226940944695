import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13f617c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-jc-sb flex-ai-c" }
const _hoisted_2 = { class: "fs-16" }
const _hoisted_3 = { class: "title-box flex flex-ai-c" }
const _hoisted_4 = { class: "name mg-r-20 fs-16" }
const _hoisted_5 = { class: "fc-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tableComponent = _resolveComponent("tableComponent")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      width: "600px",
      "before-close": _ctx.handleClose
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.query.userName), 1),
            _createElementVNode("div", _hoisted_5, "身份证号：" + _toDisplayString(_ctx.query.idCardNo), 1)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_tableComponent, {
          "table-data": _ctx.tableData,
          "table-head": _ctx.tableHead,
          "page-info": _ctx.pageInfo,
          onSizeChange: _ctx.handleSizeChange,
          onCurrentChange: _ctx.handleCurrentChange
        }, null, 8, ["table-data", "table-head", "page-info", "onSizeChange", "onCurrentChange"])
      ]),
      _: 1
    }, 8, ["modelValue", "before-close"])
  ]))
}