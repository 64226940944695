
import { defineComponent } from 'vue'
import tableComponent from '@/components/table-component/index.vue'
import { queryPayRecord, queryAdvanceRecord } from '@/api/advance.ts';
import { statusMap, colorMap } from '@/utils/map'
import { color } from 'echarts';
export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'pay'
        },
        query: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    components: {
        tableComponent,
    },
    emits: ['update:show'],
    data() {
        return {
            dialogVisible: false,
            tableHeadPay: [
                {
                    prop: 'payTime',
                    label: '发薪时间'
                },
                {
                    prop: 'paymentAmount',
                    label: '发薪金额',
                    prefix: '¥'
                },
                // {
                //     prop: 'actualAmount',
                //     label: '实发金额',
                //     color: '#409eff',
                //     prefix: '¥'
                // },
                {
                    prop: 'status',
                    label: '领薪状态',
                    map: statusMap,
                    colorMap: colorMap
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableHeadAdvance: [
                {
                    prop: 'advanceApplyTime',
                    label: '预支时间'
                },
                {
                    prop: 'advanceAmount',
                    label: '预支金额',
                    prefix: '¥'
                },
                // {
                //     prop: 'payOrderCount',
                //     label: '实际预支',
                //     color: '#409eff',
                //     prefix: '¥'
                // },
                {
                    prop: 'status',
                    label: '预支状态',
                    map: {
                        0: '未领取',
                        1: '已领取',
                        2: '停止发薪'
                    },
                    colorMap: {
                        0: '#3D61F5',
                        1: '#04CD5A',
                        2: '#F6445F'
                    }
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,

            tableData: [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
        }
    },
    computed: {
        title() {
            return (this as any).type === 'pay' ? '人员发薪明细' : '人员预支明细'
        },
        tableHead() {
            return (this as any).type === 'pay' ? this.tableHeadPay : this.tableHeadAdvance
        }
    },
    watch: {
        show(newval) {
            this.dialogVisible = newval
            if (newval) {
                this.pageInfo.pageNum = 1
                this.pageInfo.pageSize = 10
                this.pageInfo.total = 0
                this.getData()
            }
        },
        dialogVisible(newval) {
            this.$emit('update:show', newval)
        },
    },
    methods: {
        handleClose(done: Function) {
            this.dialogVisible = false
            done()
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        getData() {
            if ((this as any).type === 'pay') {
                console.log(1111, (this as any).query);
                queryPayRecord({
                    queryMonth: (this as any).query.queryDate,
                    idCardNo: (this as any).query.idCardNo,
                    pageNum: this.pageInfo.pageNum,
                    pageSize: this.pageInfo.pageSize
                }).then((res: any) => {
                    this.tableData = res.list
                    this.pageInfo.total = res.total
                })
            } else if((this as any).type === 'advance') {
                queryAdvanceRecord({
                    queryPayMonth: (this as any).query.queryDate,
                    idCardNo: (this as any).query.idCardNo,
                    pageNum: this.pageInfo.pageNum,
                    pageSize: this.pageInfo.pageSize
                }).then((res: any) => {
                    this.tableData = res.list
                    this.pageInfo.total = res.totalCount
                })
            }
        }
    }
})

